import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Form, Modal } from 'react-bootstrap';

function ManageRoles()
{
    const [roles, setRoles] = useState([]);
    const [newRole, setNewRole] = useState('');
    const [editing, setEditing] = useState(false);
    const [selectedAppID, setSelectedAppID] = useState('');
    const [editedRole, setEditedRole] = useState({
        roleID: 0,
        appID: 0,
        roleName: '',
        activeInactive: 0,
    });
    const [modal, setModal] = useState(false);
    const appOptions = [
        { appID: 1, appName: 'UserPortal' },
        { appID: 2, appName: 'Attendance' },
        { appID: 3, appName: 'InternalMentorMentee' },
        { appID: 4, appName: 'MockInterviews' },
        { appID: 5, appName: '360Feedback' }
    ];
    useEffect(() =>
    {
        axios.get('/Roles')
            .then(response => setRoles(response.data))
            .catch(error => console.error(error));
    }, []);

    const toggleModal = () => setModal(!modal);

    const handleRoleChange = (event) =>
    {
        const { name, value } = event.target;
        setEditedRole({ ...editedRole, [name]: value });
    };

    const handleNewRoleChange = (event) =>
    {
        setNewRole(event.target.value);
    };

    const handleEditRole = (role) =>
    {
        setEditing(true);
        setEditedRole(role);
        setSelectedAppID(role.appID); // Initialize the selectedAppID when editing
        toggleModal();
    };
    const handleUpdateRole = () =>
    {
        const updatedRole = {
            ...editedRole,
            appID: selectedAppID, // Ensure the appID is included in the update
        };

        axios.put(`/Roles/${updatedRole.roleID}`, updatedRole)
            .then(response =>
            {
                setRoles(roles.map(r => (r.roleID === updatedRole.roleID ? updatedRole : r)));
                setEditing(false);
                toggleModal();
            })
            .catch(error => console.error(error));
    };

    const handleAddRole = () =>
    {
        const newRoleData = {
            roleName: newRole,
            activeInactive: 1, // default to active
            appID: selectedAppID, // Use the selected appID from the dropdown
        };

        axios.post('/Roles', newRoleData)
            .then(response =>
            {
                setRoles([...roles, response.data]);
                setNewRole('');
                setSelectedAppID(''); // Reset selectedAppID after adding the role
            })
            .catch(error => console.error(error));
        toggleModal();
    };

    const handleSelectAppID = (event) =>
    {
        setSelectedAppID(event.target.value);
    };

    const handleDeleteRoleModal = () =>
    {
        axios.delete(`/Roles/${editedRole.roleID}`)
            .then(() =>
            {
                setRoles(roles.filter(role => role.roleID !== editedRole.roleID));
                toggleModal();
            })
            .catch(error => console.error(error));
    };

    return (
        <div>
            <h1>Manage Roles</h1>
            <Button variant="primary" onClick={() => { setEditing(false); toggleModal(); }}>Add New Role</Button>
            <Table striped bordered hover className="mt-3 custom-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>App Relation</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {roles.map(role =>
                    {
                        const appName = appOptions.find(app => app.appID === role.appID)?.appName || 'N/A';

                        return (
                            <tr key={role.roleID}>
                                <td>{role.roleID}</td>
                                <td>{role.roleName}</td>
                                <td>{appName}</td>
                                <td>{role.activeInactive ? 'Active' : 'Inactive'}</td>
                                <td>
                                    <Button variant="outline-secondary" onClick={() => handleEditRole(role)}>Edit</Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Modal show={modal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editing ? 'Edit Role' : 'Add New Role'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="roleName">
                            <Form.Label>Role Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="roleName"
                                onChange={editing ? handleRoleChange : handleNewRoleChange}
                                value={editing ? editedRole.roleName : newRole}
                                className="custom-input"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="appName">
                            <Form.Label>App Name</Form.Label>
                            <Form.Control as="select" value={selectedAppID} onChange={handleSelectAppID}>
                                <option value="">Select App</option>
                                {appOptions.map((app) => (
                                    <option key={app.appID} value={app.appID}>
                                        {app.appName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editing && (
                        <Button variant="danger" onClick={handleDeleteRoleModal}>
                            Delete Role
                        </Button>
                    )}
                    <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    <Button variant="primary" onClick={editing ? handleUpdateRole : handleAddRole}>
                        {editing ? 'Update Role' : 'Add Role'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ManageRoles;
