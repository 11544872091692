import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/Form.css';
import axios from 'axios';

function UserForm()
{
    const [data, setData] = useState({
        userId: '',
        userName: '',
        userEmail: '',
        roles: [],
        activeInactive: ''
    });
    const appOptions = [
        { appID: 1, appName: 'UserPortal' },
        { appID: 2, appName: 'Attendance' },
        { appID: 3, appName: 'InternalMentorMentee' },
        { appID: 4, appName: 'MockInterviews' },
        { appID: 5, appName: '360Feedback' }
    ];
    const [availableRoles, setAvailableRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    const getRoleAppName = (appID) =>
    {
        const appOption = appOptions.find(app => app.appID === appID);
        return appOption ? appOption.appName : 'Unknown App';
    };

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            try
            {
                const roleRequest = axios.get('/Roles');
                const userRequest = id !== 'new' ? axios.get(`/Users/${id}`) : null;

                const [roleResponse, userResponse] = await axios.all([roleRequest, userRequest]);

                if (userResponse && userResponse.data)
                {
                    setData(userResponse.data);
                }

                if (roleResponse && roleResponse.data)
                {
                    setAvailableRoles(roleResponse.data);
                }
            } catch (ex)
            {
                if (ex.response && ex.response.status === 404)
                {
                    navigate('/not-found', { replace: true });
                }
            }
        };

        fetchData();
    }, [id, navigate]);

    const handleChange = (e) =>
    {
        const { name, value } = e.target;
        setData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleRoleChange = (e) =>
    {
        setSelectedRole(e.target.value);
    };

    const addRole = async () =>
    {
        const roleToAdd = availableRoles.find(r => r.roleName === selectedRole);
        if (roleToAdd && !data.roles.some(role => role.roleName === roleToAdd.roleName))
        {
            try
            {
                // Construct the new user role object
                const newUserRole = {
                    userId: data.userId,
                    roleId: roleToAdd.roleID,
                };

                const response = await axios.post('/UserRoles', newUserRole);
                // If the POST request is successful, update the local state
                setData(prevData => ({
                    ...prevData,
                    roles: [...prevData.roles, {
                        ...roleToAdd,
                        userRoleID: response.data.userRoleID
                    }]
                }));
            } catch (error)
            {
                console.error('Failed to add role:', error);
            }
        }
    };

    const removeRole = async (roleToRemove) =>
    {
        try
        {
            await axios.delete(`/UserRoles/${roleToRemove.userRoleID}`, {
                userRoleId: roleToRemove.userRoleID,
                userId: data.userId,
                roleId: roleToRemove.roleId,
            });
            setData(prevData => ({
                ...prevData,
                roles: prevData.roles.filter(role => role.userRoleID !== roleToRemove.userRoleID)
            }));
        } catch (error)
        {
            console.error('Failed to remove role:', error);
        }
    };
    // const handleUpdateRole = () =>
    // {
    //     const updatedRole = {
    //         ...editedRole,
    //         appID: selectedAppID, // Ensure the appID is included in the update
    //     };

    //     axios.put(`/Roles/${updatedRole.roleID}`, updatedRole)
    //         .then(response =>
    //         {
    //             setRoles(roles.map(r => (r.roleID === updatedRole.roleID ? updatedRole : r)));
    //             setEditing(false);
    //             toggleModal();
    //         })
    //         .catch(error => console.error(error));
    // };
    const handleSubmit = async (e) =>
    {
        console.log(data)
        e.preventDefault();
        const userToSave = {
            activeInactive: parseInt(data.activeInactive, 10),
            userEmail: data.userEmail,
            userId: data.userId,
            userFullName: data.userName,
        };

        axios.put(`/Users/${data.userId}`, userToSave)
            .catch(error => console.error(error));
        navigate('/users');
    };

    return (
        <div className="container mt-5">
            <h1 className="mb-4">User Update Form</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="userId" className="form-label">ID</label>
                    <input type="text" id="userId" name="userId" value={data.userId} onChange={handleChange} className="form-control" readOnly />
                </div>

                <div className="mb-3">
                    <label htmlFor="userName" className="form-label">Name</label>
                    <input type="text" id="userName" name="userName" value={data.userName} onChange={handleChange} className="form-control" />
                </div>

                <div className="mb-3">
                    <label htmlFor="userEmail" className="form-label">Email</label>
                    <input type="email" id="userEmail" name="userEmail" value={data.userEmail} onChange={handleChange} className="form-control" />
                </div>

                <div className="mb-3">
                    <label htmlFor="roles" className="form-label">Roles</label>
                    <div className="input-group">
                        <select id="roles" value={selectedRole} onChange={handleRoleChange} className="form-select">
                            <option value="" disabled>Select a role</option>
                            {availableRoles.map(role => (
                                <option key={role.roleID} value={role.roleName}>{getRoleAppName(role.appID) + ": " + role.roleName}
                                </option>
                            ))}
                        </select>
                        <button type="button" onClick={addRole} className="btn btn-primary">Add Role</button>
                    </div>
                    <ul className="list-group mt-2">
                        {data.roles.map((role, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                {getRoleAppName(role.appID) + ": " + role.roleName}
                                <button type="button" onClick={() => removeRole(role)} className="btn btn-outline-danger btn-sm">Remove</button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Active/Inactive */}
                <div className="mb-3">
                    <label htmlFor="activeInactive" className="form-label">Status</label>
                    <select id="activeInactive" name="activeInactive" value={data.activeInactive} onChange={handleChange} className="form-select">
                        <option value="0">Inactive</option>
                        <option value="1">Active</option>
                    </select>
                </div>

                <button type="submit" className="btn btn-success">Save</button>
            </form>
        </div>
    );
}

export default UserForm;
